"use strict";

var _vue = _interopRequireDefault(require("vue"));

var _SvgIcon = _interopRequireDefault(require("@/components/SvgIcon"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// svg component
// register globally
_vue.default.component('SvgIcon', _SvgIcon.default);

const req = require.context('./svg', false, /\.svg$/);

const requireAll = requireContext => requireContext.keys().map(requireContext);

requireAll(req);