var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "component",
    _vm._b(
      {
        nativeOn: {
          touchstart: function ($event) {
            return _vm.handleClick($event)
          },
        },
      },
      "component",
      _vm.linkProps(_vm.to),
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }