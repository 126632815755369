"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _components = require("./components");

var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Layout',
  components: {
    Navbar: _components.Navbar,
    Sidebar: _components.Sidebar,
    AppMain: _components.AppMain
  },
  mixins: [_ResizeHandler.default],
  data: () => {
    return {
      notifier: null
    };
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },

    device() {
      return this.$store.state.app.device;
    },

    fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },

    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }

  },

  created() {
    this.$on('offline', () => {
      if (this.notifier) {
        this.notifier.close();
      }

      this.notifier = this.$notify({
        title: 'Connection Status',
        message: 'You\'re offline',
        type: 'warning',
        showClose: false,
        duration: 0,
        onClose: () => {
          this.notifier = null;
        },
        offset: 44
      });
    });
    this.$on('online', () => {
      if (this.notifier) {
        this.notifier.close();
      }

      this.notifier = this.$notify({
        title: 'Connection Status',
        message: 'back online',
        type: 'success',
        duration: 1800,
        onClose: () => {
          this.notifier = null;
        },
        offset: 44
      });
    });

    if (!window.navigator.onLine) {
      this.notifier = this.$notify({
        title: 'Connection Status',
        message: 'You\'re offline',
        type: 'warning',
        showClose: false,
        duration: 0,
        onClose: () => {
          this.notifier = null;
        },
        offset: 44
      });
    }
  },

  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }

  }
};
exports.default = _default;