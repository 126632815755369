"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _validate = require("@/utils/validate");

var _Item = _interopRequireDefault(require("./Item"));

var _Link = _interopRequireDefault(require("./Link"));

var _FixiOSBug = _interopRequireDefault(require("./FixiOSBug"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarItem',
  components: {
    Item: _Item.default,
    AppLink: _Link.default
  },
  mixins: [_FixiOSBug.default],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },

  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    return {
      onlyOneChild: null
    };
  },

  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      }); // When there is only one child router, the child router is displayed by default

      if (showingChildren.length === 1) {
        return true;
      } // Show parent if there are no child router to display


      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent,
          path: '',
          noShowingChildren: true
        };
        return true;
      }

      return false;
    },

    resolvePath(routePath) {
      if ((0, _validate.isExternal)(routePath)) {
        return routePath;
      }

      if ((0, _validate.isExternal)(this.basePath)) {
        return this.basePath;
      }

      return this.absolute(this.basePath, routePath);
    },

    absolute(base, relative) {
      const parts = relative.split('/');
      let stack = '';

      if (!relative) {
        return base.replace('//', '/');
      }

      if (!relative[0] || relative[0] !== '/') {
        stack = base.split('/');
        stack.pop(); // remove current file name (or empty string)
      } else {
        return relative.replace('//', '/');
      } // (omit if "base" is the current folder without trailing slash)


      for (let i = 0; i < parts.length; i++) {
        if (parts[i] === '.') {
          continue;
        }

        if (parts[i] === '..') {
          stack.pop();
        } else {
          stack.push(parts[i]);
        }
      }

      return stack.join('/').replace('//', '/');
    }

  }
};
exports.default = _default;