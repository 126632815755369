var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden
    ? _c(
        "div",
        [
          _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
          _vm.onlyOneChild.meta &&
          (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
          !_vm.item.alwaysShow
            ? [
                _c(
                  "app-link",
                  { attrs: { to: _vm.resolvePath(_vm.onlyOneChild.path) } },
                  [
                    _c(
                      "el-menu-item",
                      {
                        class: { "submenu-title-noDropdown": !_vm.isNest },
                        attrs: {
                          index: _vm.resolvePath(_vm.onlyOneChild.path),
                        },
                      },
                      [
                        _c("item", {
                          attrs: {
                            icon:
                              _vm.onlyOneChild.meta.icon ||
                              (_vm.item.meta && _vm.item.meta.icon),
                            title: _vm.$t(
                              "route." + _vm.onlyOneChild.meta.title
                            ),
                            locale: _vm.$i18n.locale,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _c(
                "el-submenu",
                {
                  ref: "subMenu",
                  attrs: {
                    index: _vm.resolvePath(_vm.item.path),
                    "popper-append-to-body": "",
                  },
                },
                [
                  _vm.item.meta
                    ? _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("item", {
                            attrs: {
                              icon: _vm.item.meta && _vm.item.meta.icon,
                              title: _vm.$t("route." + _vm.item.meta.title),
                              locale: _vm.$i18n.locale,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.item.children, function (child) {
                    return _c("sidebar-item", {
                      key: child.path + child.name + _vm.$i18n.locale,
                      staticClass: "nest-menu",
                      attrs: {
                        "is-nest": true,
                        item: child,
                        "base-path": _vm.resolvePath(child.path),
                      },
                    })
                  }),
                ],
                2
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }