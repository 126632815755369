"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  route: {
    ChangePassword: '俢改密碼',
    Dashboard: 'Dashboard',
    Report: '報告',
    Reports: '報告',
    LabourCost: '人力成本報表',
    StaffAttendance: '員工出勤報表',
    Productivity: '生產效率報表',
    UserManagement: '用戶管理',
    POS: 'POS',
    COGS: '銷貨成本報表',
    Modifier: 'Modifier',
    Item: 'Item',
    Product: '產品',
    ProductType: 'Product Type',
    SubProductType: 'Sub Product Type',
    StoreProduct: '店舖產品',
    AddStoreProduct: '新增店舖產品',
    EditStoreProduct: '編輯店舖產品',
    StoreProductCombo: '店舖產品組合',
    AddStoreProductCombo: '新增店舖產品組合',
    EditStoreProductCombo: '編輯店舖產品組合',
    Category: '類別',
    Kitchen: 'Kitchen Station',
    Users: '用戶',
    EditUser: '編輯用戶',
    AddUser: '新增用戶',
    MOOMOOMemberManagement: 'MOOMOO 會員管理',
    MOOMOOMembers: 'MOOMOO會員',
    ShowMOOMOOMember: '顯示 MOOMOO 會員',
    EditMOOMOOMember: '編輯 MOOMOO 會員',
    AddMOOMOOMember: '新增 MOOMOO 會員',
    DeliveryTypeManagement: '外賣方式管理',
    DeliveryTypes: '外賣方式',
    ShowDeliveryTypes: '顯示外賣方式',
    AddDeliveryTypes: '新增外賣方式',
    EditDeliveryTypes: '編輯外賣方式',
    StoreManagement: '店舖管理',
    Stores: '店舖',
    EditStore: '編輯店舖',
    Sale: 'Sale',
    SaleUpload: 'Sale Upload',
    SalaryReport: 'Salary Report',
    AddStore: '加店舖',
    Roster: '更表',
    AttendanceManagement: '出勤管理',
    Attendances: '出勤',
    AddAttendance: '加出勤',
    Leaves: '請假',
    LeaveApplication: '請假提交',
    LeaveType: '假期管理',
    LeaveSummary: '請假總結',
    LeaveAdd: '加請假',
    LeaveEdit: '改請假',
    LeaveHistory: '請假記錄',
    PurchaseOrderHistory: '採購訂單PO記錄',
    RoleMapping: '適用員工',
    LeaveApproval: '批核請假',
    LeaveSetting: '假期設定',
    Payroll: '薪金',
    Shifts: '輪班時間',
    EditShift: 'Edit 輪班時間',
    AddShift: '加輪班時間',
    ExpenseClaims: 'Expense Claims',
    ExpenseClaimList: '洗費報銷',
    AddExpenseClaim: 'Add Expense Claim',
    EditExpenseClaim: 'Edit Expense Claim',
    Message: '訊息',
    EditMessage: '編輯訊息',
    AddMessage: '加訊息',
    MaterialPlanning: '採購',
    Supplier: '供應商',
    EditSupplier: '改供應商',
    AddSupplier: '加供應商',
    RawMaterial: '原材料',
    EditRawMaterial: '編輯原材料',
    AddRawMaterial: '新增原材料',
    RawMaterialScheduledPrice: '預定原材料價錢',
    AddRawMaterialScheduledPrice: '新增預定原材料價錢',
    PurchaseRequests: '採購PR',
    EditPurchaseRequest: '編輯採購PR',
    AddPurchaseRequest: '新增採購PR',
    PurchaseOrders: '採購訂單PO',
    PurchaseOrdersRequest: '採購PO提交',
    EditPurchaseOrder: '編輯採購訂單PO',
    AddPurchaseOrder: '新增採購訂單PO',
    GoodReceived: '收貨GR',
    EditGoodReceived: '編輯收貨GR',
    AddGoodReceived: '新增收貨GR',
    WarehouseManagement: '倉庫管理',
    Warehouses: '倉庫',
    AddWarehouse: '新增倉庫',
    EditWarehouseArea: '編輯倉庫區域',
    WarehouseArea: '倉庫區域',
    WarehouseAreas: '倉庫區域',
    WarehouseAreasRawMaterial: '倉庫區域原材料',
    StockIn: '存入',
    StockOut: '出庫',
    StockTake: '盤點',
    // StockTake: '出庫',
    StockTransit: '移倉',
    WarehouseRecords: '倉庫記錄',
    WarehouseInventoryLv: '庫存水平',
    List: 'List',
    Order: '外賣系統',
    Orders: '訂單',
    EditOrder: '編輯訂單',
    SummaryOrder: '概括',
    Labour: '人力資源',
    CRM: '客戶管理',
    Schedule: '更表',
    CashExpense: '現金支出',
    CashExpenseEdit: '編輯現金支出',
    CashExpenseAdd: '新增現金支出',
    CashExpenseList: '現金支出列表',
    CashExpenseReport: '現金支出報告',
    UserOTRecord: '用戶 OT 紀綠'
  },
  Supplier: {
    AddSupplier: '新增供應商',
    Name: '名稱',
    Actions: '動作',
    Edit: '編輯',
    ConfirmDeleteMessage: '真的要刪?',
    Confirm: '確認',
    Delete: '刪除',
    Cancel: '取消',
    Recorddeleted: '記錄已刪',
    ContactName: '聯繫人名',
    Tel: '電話',
    Email: '電郵地址',
    Address: '地址',
    Remark: '備往',
    RequiredNameMessage: '名稱需要先',
    IncorrectEmailMessage: '請寫好電郵地址',
    UpdateFailedMessage: '提交失敗, 請重試一次',
    Add: '新增',
    Supplierupdated: '供應商已创建',
    Suppliercreated: '供應商已创建'
  },
  Warehouse: {
    AddWarehouse: '新增倉庫',
    Name: '名稱',
    Action: '動作',
    Actions: '動作',
    Delete: '刪除',
    ConfirmDeleteMessage: '確認刪除',
    Confirm: '確認',
    Cancel: '取消',
    Recorddeleted: '記錄已刪',
    Area: '區域',
    Areas: '區域',
    AddWarehouseArea: '新增倉庫區域',
    AddWarehouseAreaRawMaterial: '新增原材料',
    SKU: 'SKU',
    QTY: '數量',
    Back: '返回',
    StockIn: '存入',
    StockOut: '出庫',
    History: '記錄',
    InventoryLv: 'Inventory Lv',
    DownloadQR: '下載QR',
    totalStock: '所有倉存',
    availableStock: '可用倉存',
    HiddenWarehouse: '實際存倉倉庫',
    Yes: '是',
    No: '否'
  },
  WarehouseArea: {
    Name: '名稱',
    Add: '新增',
    Edit: '編輯',
    Submit: '提交',
    Recorddeleted: '記錄已刪',
    ConfirmDeleteMessage: '真的要刪？',
    Confirm: '確認',
    Delete: '刪除',
    Cancel: '取消',
    Warehouse: '倉庫',
    WarehouseAreacreated: '倉庫區域已创建',
    WarehouseAreaupdated: '倉庫區域已创建',
    UpdateFailedMessage: '提交失敗',
    RawMaterial: '原材料',
    History: '記錄'
  },
  RawMaterialScheduledPrice: {
    AddRawMaterialScheduledPrice: '新增預定原材料價錢',
    StartDate: '開始日期',
    EndDate: '結束日期',
    Price: '價格',
    Actions: '動作',
    Action: '動作',
    Add: '新增',
    Edit: '編輯',
    Submit: '提交',
    Recorddeleted: '記錄已刪',
    ConfirmDeleteMessage: '決定刪資料?',
    Confirm: '確認',
    Delete: '刪除',
    Cancel: '取消',
    Back: '返回',
    UpdateFailedMessage: '提交失敗, 請重試一次.',
    RequiredMessage: '需要先',
    UpdatedAt: '改時間',
    CreatedBy: '新增者'
  },
  WarehouseAreaRawMaterial: {
    Name: '名稱',
    Add: '新增',
    Edit: '編輯',
    Submit: '提交',
    Recorddeleted: '記錄已刪',
    ConfirmDeleteMessage: '真的要刪？',
    Confirm: '確認',
    Delete: '刪除',
    Cancel: '取消',
    Warehouse: '倉庫',
    WarehouseArea: '倉庫區域',
    WarehouseAreaRawMaterialcreated: '倉庫區域已创建',
    WarehouseAreaRawMaterialupdated: '倉庫區域已创建',
    UpdateFailedMessage: '提交失敗',
    RawMaterial: '原材料',
    StockOut: '出庫',
    StockIn: '存入',
    StockTake: '盤點',
    StockTransit: '移倉',
    History: '記錄'
  },
  WarehouseRecord: {
    Warehouse: '倉庫',
    WarehouseArea: '倉庫區域',
    ToWarehouse: '地頭倉庫',
    ToWarehouseArea: '地頭倉庫區域',
    RawMaterial: '原材料',
    Name: '名稱',
    Actions: '動作',
    QTY: '數量',
    MainQty: '主單位數量',
    SubQty: '散數單位數量',
    Remark: '備往',
    Action: '動作',
    Add: '新增',
    Edit: '編輯',
    Submit: '提交',
    Recorddeleted: '記錄已刪',
    ConfirmDeleteMessage: '真的要刪？',
    Confirm: '確認',
    Delete: '刪除',
    Cancel: '取消',
    Back: '返回',
    WarehouseRecordcreated: '倉庫已创建',
    UpdateFailedMessage: '提交失敗, 請重試一次',
    RequiredMessage: '需要先',
    UpdatedBy: '用戶編輯',
    UpdatedAt: '改時間'
  },
  GoodReceivedNotes: {
    All: '全部',
    WarehouseArea: '倉庫區域',
    AddGoodReceivedNotes: '加收貨GR',
    Confirm: '確認',
    POOutstanding: 'PO懸掛數量',
    Confirmed: '確認',
    Completed: '完成',
    Drafted: '草稿',
    Date: '日期',
    Processing: '確認',
    Items: '原材料',
    ItemAdd: '加Item',
    Warehouse: '倉庫',
    RequestQty: '數量要求',
    Result: 'Result',
    RawMaterial: '原材料',
    OutstandingQty: '懸掛數量',
    Qty: '數量',
    ReceivedQty: '收數量',
    Operations: '動作',
    Remark: '備往',
    Name: '名稱',
    Keyword: 'Keyword',
    Action: '動作',
    Search: '授索',
    Select: 'Select',
    DownloadTransitNote: '下載 Transit Note',
    POQty: 'PO數量',
    POReceived: 'PO 收數量',
    POOutstaing: 'PO 懸掛',
    Received: 'Received',
    Allocated: 'Allocated',
    PendingAllocation: 'Pending Allocation',
    BackToList: '返回',
    Copy: '復制',
    Status: '進度',
    UpdatedAt: '改時間',
    PONumber: 'PO號碼',
    GoodReceivedDate: 'GR 日期',
    Save: '儲存',
    Submit: '提交',
    Delete: '刪除',
    Unsubmit: 'Unsubmit',
    Reject: '拒絕',
    Complete: '完成',
    DownloadGRN: '下載 Good Received Note',
    DownloadTN: '下載 Transit Note',
    RequiredStatus: '進度需要先',
    RequiredSupplier: '供應商需要先',
    PurchaseRequestUpdated: 'PR 已创建',
    PurchaseRequestCreated: 'PR 已创建',
    UpdateFailed: '提交失敗, 請重試一次',
    CreateFailed: 'Create失敗, 請重試一次。',
    DeleteFailed: '刪除失敗, 請重試一次',
    RequestedQty: '數量要求',
    BackToPO: '返回 PO',
    ConfirmRequest: '真的要改成確認?',
    ConfirmRequestFailed: '改成確認失敗, 請重試一次',
    CompleteRequestFailed: '改成完成失敗, 請重試一次',
    ConfirmDelete: '真的要刪？',
    ConfirmRequrest: '真的要改成確認?',
    Deleted: '記錄刪了',
    RequestConfirmed: '改成確認了',
    RequestCompleted: '要求改完成了',
    InvalidInput: 'Invalid Input',
    Type: 'Type'
  },
  PurchaseOrders: {
    Requested: '採購PR',
    PurchaeOrdersText: 'Purchase Orders Text',
    PurchaseOrdersText: 'Purchase Orders Text',
    Search: '授索',
    RawMaterial: '原材料',
    Processing: '確認',
    Submitted: '提交',
    Confirmed: '確認',
    Outstanding: '處理',
    Completed: '完成',
    Action: '動作',
    ShareWhatsApp: 'Share on Whatsapp',
    Select: 'Select',
    KeyWord: 'KeyWord',
    Status: '進度',
    Result: 'Result',
    PODate: 'PO 日期',
    DelivDate: 'Delivery 日期',
    DelivAddress: 'Delivery 地址',
    ContactName: '聯繫人名',
    ContactTel: '電話',
    ContactEmail: '聯繫電郵地址',
    ContactAddress: '聯繫地址',
    SuppContactName: '供應商聯繫人名',
    SuppContactTel: '供應商電話',
    SuppContactEmail: '供應商電郵地址',
    PurchaseRequestItem: '採購PR原材料',
    AddItem: '加原材料',
    WareHouse: '倉庫',
    ReqQty: '數量要求',
    Name: '名稱',
    Qty: '數量',
    UnitPrice: '單價',
    Subtotal: 'Subtotal',
    Remark: '備往',
    Operations: '動作',
    ReceiveRemark: '收備往',
    RelatedPR: 'Related PR',
    RelatedGR: 'Related GR',
    DownloadPO: '下載 PO',
    Copy: '復制',
    BackToList: '返回',
    RequiredStatus: '進度需要先',
    RequiredSupp: '供應商需要先',
    PRUpdated: 'PR 已创建',
    PRCreated: 'PR 已创建',
    POUpdated: 'PO 已创建',
    POCreated: 'PO 已创建',
    UpdateFailed: '提交失敗, 請重試一次',
    CreateFailed: 'Create失敗, 請重試一次',
    DeleteFailed: '刪除失敗, 請重試一次',
    ConfirmFailed: '改確認失敗, 請重試一次',
    ConfirmDelete: '真的要刪？',
    ConfirmRequest: '真的要改狀態成為確認?',
    ConfirmGenerateGR: '真的要 generate GR?',
    GRFailed: '生成GR失敗, 請重試一次',
    DownloadFailed: '下載失敗, 請重試一次',
    ReqConfirmed: '改確認了',
    Delete: '刪除',
    Cancel: '取消',
    Deleted: '紀綠已刪',
    Save: '儲存',
    Confirm: '確認',
    buttonSubmit: '提交',
    buttonDelete: '刪除',
    buttonUnsubmit: 'Unsubmit',
    buttonConfirm: '確認',
    buttonReject: '拒絕',
    buttonComplete: '完成',
    buttonDownload: '下載',
    buttonGenerateGR: 'Generate GR',
    GRGenerated: 'Good Received Note Generated'
  },
  PurchaseRequests: {
    Items: '原材料',
    Category: '類別',
    TargetRestockDate: 'Target Restock',
    Status: '進度',
    UpdatedAt: '改時間',
    PurchaseOrders: '採購訂單',
    Store: '店舖',
    Search: '授索',
    ConfirmToGeneratePO: '真的要 Generate PO?',
    Name: '名稱',
    Actions: '動作',
    QTY: '數量',
    Action: '動作',
    Add: '新增',
    Edit: '編輯',
    Show: '顯示',
    Cancel: '取消',
    POGenerated: 'PO Generated',
    CantGeneratePOGenerated: 'Generate PO 失敗: ',
    RecordDeleted: '記錄刪了',
    ConfirmDeleteMessage: '真的要刪？',
    Confirm: '確認',
    Delete: '拒絕',
    Filter: '篩選',
    WarehouseRecordcreated: '倉庫已创建',
    UpdateFailedMessage: '提交失敗, 請重試一次.',
    RequiredMessage: '需要先',
    SubTotal: 'Sub Total',
    UnitPrice: '基本價格',
    Qty: '數量',
    Item: '原材料',
    Stores: '店舖',
    PickStores: '先店舖店舖',
    PickDate: '选择日期',
    All: '全部',
    Drafter: '草稿',
    PendingApproval: '待批',
    Processing: '處理',
    Completed: '完成',
    PurchaseRequestItem: 'PR 原材料',
    Operations: '動作',
    Remark: '備往',
    RelatedPO: 'Related PO',
    AddItem: '加原材料',
    Fullfilled: '完成',
    Back: '返回',
    Save: '儲存',
    Submit: '提交',
    UnSubmit: 'Unsubmit',
    Reject: '拒絕',
    Submitted: '提交',
    Confirmed: '確認',
    Outstanding: '處理',
    Drafted: '草稿',
    Complete: '完成',
    Download: '下載',
    Generate: 'Generate',
    GeeneratePO: 'Generate PO',
    RequiredStatus: '進度需要先',
    RequiredStore: '店舖需要先',
    UpdatedPurchaseRequests: 'PR 已创建',
    UpdateFailed: '提交失敗, 請重試一次',
    CreatedPurchaseRequests: 'PR 已创建',
    CreateFailed: 'Create失敗, 請重試一次',
    ConfirmDelete: '真的要刪？',
    DeleteFailed: '刪除失敗, 請重試一次. ',
    ConfirmSubmitRequest: 'Confirm to submit request?',
    ConfirmUnsubmitRequest: 'Confirm to unsubmit request? ',
    ConfirmPurchaseRequest: 'Confirm to confirm Request?',
    ConfirmRequestFailed: '改成確認失敗, 請重試一次',
    ConfirmRejectRequest: 'Confirm to reject request?',
    RequestSubmitted: 'Request Submitted',
    RequestRejected: 'Request Rejected',
    SubmitRequestFailed: 'Submit Request失敗, 請重試一次.',
    RequestConfirmed: '改確認了',
    RejectRequestFailed: '拒絕要求失敗, 請重試一次',
    ConfirmCompleteRequest: '真的要改成完成?',
    RequestCompleted: '要求改成完成',
    CompleteRequestFailed: '改成完成失敗, 請重試一次',
    ConfirmGeneratePO: 'Confirm to Generate PO?',
    GeneratePOFailed: 'Generate PO失敗, 請重試一次',
    DownloadFailed: '下載失敗, 請重試一次',
    ChooseRawMaterial: '選原材料',
    KeyWord: 'KeyWord',
    Result: '結果',
    RawMaterial: '原材料'
  },
  Category: {
    LoadFail: 'Load Fail',
    SubmitFail: 'Submit Fail',
    Created: '類別已创建',
    Updated: '類別已创建',
    Deleted: '類別已刪'
  },
  ProductType: {
    Add: 'Add Product Type',
    Edit: 'Edit Product Type',
    Created: 'Product Type 已创建',
    Updated: 'Product Type 已创建',
    Deleted: 'Product Type 已刪'
  },
  SubProductType: {
    Add: '加Sub Product Type',
    Edit: 'Edit Sub Product Type',
    Created: 'Sub Product Type 已创建',
    Updated: 'Sub Product Type 已创建',
    Deleted: 'Sub Product Type 已刪'
  },
  Modifier: {
    Add: 'Add Modifier',
    Edit: 'Edit Modifier',
    Created: 'Modifier 已创建',
    Updated: 'Modifier 已创建',
    Deleted: 'Modifier 已刪'
  },
  Item: {
    Add: 'Add Item',
    Edit: 'Edit Item',
    Created: 'Item 已创建',
    Updated: 'Item 已创建',
    Deleted: 'Item 已刪',
    PresetSaved: 'Preset Saved',
    LoadSuccess: 'Load Success',
    ImportSuccess: 'Import Success'
  },
  Order: {
    emptyText: '暫無資料',
    Name: '名稱',
    Actions: '動作',
    Add: '新增',
    Confirm: '確認',
    Search: '授索',
    All: '全部',
    Action: '動作',
    Show: '顯示',
    Edit: '編輯',
    Summary: '概括',
    AddOrder: '新增訂單',
    AddStore: '登記店舖',
    Store: '店舖',
    Type: '方式',
    Status: '進度',
    Price: '價格',
    Tel: '電話',
    ContactTel: '電話',
    Email: '電郵',
    ContactEmail: '聯絡電鄄',
    Remark: '備往',
    Product: '產品',
    Delete: '刪除',
    AddProduct: '新增產品',
    Create: '新增',
    Update: '修改',
    Cancel: '取消',
    Date: '日期',
    Save: '儲存',
    Submit: '提交',
    Reject: '拒絕',
    Approve: '批准',
    Download: '下載',
    Leave: '請假',
    UpdatedAt: '改時間',
    User: '用戶',
    Total: '總共'
  },
  StoreProduct: {
    Store: '店舖',
    ProductName: '產品名稱',
    Price: '價格',
    Cost: '成本',
    Name: '名稱',
    Actions: '動作',
    Edit: '編輯',
    ConfirmDeleteMessage: '真的要刪？',
    Confirm: '確認',
    Delete: '刪除',
    Cancel: '取消',
    Back: '返回',
    AddStoreProduct: '新增店鋪產品',
    Combo: '組合',
    Recorddeleted: '記錄已刪',
    ContactName: '聯繫人名',
    Tel: '電話',
    Email: '電郵地址',
    Address: '地址',
    Remark: '備往',
    RequiredNameMessage: '產品名稱需要先',
    IncorrectEmailMessage: '請寫好電郵地址',
    UpdateFailedMessage: '提交失敗, 請重試一次',
    Add: '新增',
    StoreProductupdated: '店鋪產品已创建',
    StoreProductcreated: '店鋪產品已创建'
  },
  StoreProductCombo: {
    Store: '店舖',
    ProductName: '產品名稱',
    Price: '價格',
    RawMaterial: '原材料',
    RawMaterialPrice: '原材料價錢',
    PercentageOf: '百份比 (份量)',
    PercentagePrice: '份量價錢',
    Cost: '成本',
    StoreStock: '店舖倉存',
    WholeStock: '總倉存',
    Name: '名稱',
    Actions: '動作',
    Edit: '編輯',
    ConfirmDeleteMessage: '真的要刪？',
    Confirm: '確認',
    Delete: '刪除',
    Cancel: '取消',
    Back: '返回',
    AddStoreProductCombo: '新增店鋪產品組合',
    Combo: '組合',
    Recorddeleted: '記錄已刪',
    ContactName: '聯繫人名',
    Tel: '電話',
    Email: '電郵地址',
    Address: '地址',
    Remark: '備往',
    RequiredRawMaterialMessage: '原材料需要先',
    IncorrectEmailMessage: '請寫好電郵地址',
    UpdateFailedMessage: '提交失敗, 請重試一次',
    Add: '新增',
    StoreProductComboupdated: '店鋪產品組合已创建',
    StoreProductCombocreated: '店鋪產品組合已创建'
  },
  passwords: {
    'throttled': '太多要求, 請等下陳'
  },
  CashExpense: {}
};
exports.default = _default;