"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _store = _interopRequireDefault(require("@/store"));

var _lodash = require("lodash");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const {
  body
} = document;
const WIDTH = 992; // refer to Bootstrap's responsive design

var _default = {
  watch: {
    $route() {
      if (this.device === 'mobile' && this.sidebar.opened) {
        _store.default.dispatch('app/closeSideBar', {
          withoutAnimation: false
        });
      }
    }

  },

  beforeMount() {
    this.debouncer = (0, _lodash.debounce)(() => this.$_resizeHandler(), 220);
    window.addEventListener('resize', this.debouncer, {
      passive: true
    });
  },

  beforeDestroy() {
    if (this.debouncer) {
      window.removeEventListener('resize', this.debouncer, {
        passive: true
      });
    }
  },

  mounted() {
    const isMobile = this.$_isMobile();

    if (isMobile) {
      _store.default.dispatch('app/toggleDevice', 'mobile');

      _store.default.dispatch('app/closeSideBar', {
        withoutAnimation: true
      });
    }
  },

  data() {
    return {
      debouncer: null
    };
  },

  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_isMobile() {
      const rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    },

    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile();

        _store.default.dispatch('app/toggleDevice', isMobile ? 'mobile' : 'desktop');

        if (isMobile) {
          _store.default.dispatch('app/closeSideBar', {
            withoutAnimation: true
          });
        }
      }
    }

  }
};
exports.default = _default;